.image {
    width: 60%;
    text-align: center;
}

.divImage {
    text-align: center;
}

.navigationButton {
    text-align: center;
    font-size: 35px;
}

.pdfLabel {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.pdf {
    height: 800px;
    width: 100%;
}

.actionMenu {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

@media (max-width: 920px) {
    .image {
        width: 100%;
    }
}