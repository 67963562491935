body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  padding: 0 2rem;
}

.main {
  min-height: 85vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description {
  margin: 1rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.pdfButton {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.lines {
  display: flex;
  flex-direction: row;  
  justify-content: center;  
  padding: 0 20%;
  margin: 8px 0;
}

h1, h2 {
  text-align: center;
}

.urltext {
  width: 100%;
  border-radius: 4px;
  padding: 14px 20px;
  cursor: pointer;
}

.intervalsarea {
  width: 100%;
  border-radius: 4px;
  border: 2px solid #00000f;
  box-sizing: border-box;
  margin: 8px 0;
  padding: 14px 20px;
  cursor: pointer;  
  resize: none;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.button {
  border-radius: 8px;
  border: 1px solid #00000f;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  padding: 8px;
  transition-duration: 0.4s;
  margin-left: 4px;
}

.button:hover {
  background-color: #afb8af; /* Green */
  color: white;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  
  bottom: 100%;
  left: 100%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 1s;
}

.tooltip {
  position: relative;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}  

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.txtboxlarge {
  width: 70%;
}

.txtboxfull {
  width: 100%;
}

@media (max-width: 920px) {
    .main {
    padding: 0;
  }

  .container {
    padding: 0;
  }

  .lines {
    padding: 0 0.9rem;
  }
}