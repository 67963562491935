p {
  text-align: center;
}

.title {
  text-align: center;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.links .item {
  padding-left: 1rem;
}