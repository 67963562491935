p {
    text-align: center;
}

.title {
    text-align: center;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: center;  
  }