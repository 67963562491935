.processing-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #aaabbb;
    opacity: 0.4;
    z-index: 10;
    pointer-events:none;
}

.processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    pointer-events:none;
}