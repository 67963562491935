.header a:hover,
.header a:focus,
.header a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 1.5rem;
  margin: 2rem 0;

  background-color: #fff;
  color: #000;
  padding: 20px;
  text-align: center;
}

.title,
.description {
  text-align: center;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  padding: 10px;
}

.links .item {
  padding-left: 1rem;
}

.header {
  background: #fff;
  /* position: fixed; */
  top: 0;
  width: 100%;
  margin-top: 10px;
}

.header a {
  color: #000;
  text-decoration: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky+.content {
  padding-top: 102px;
}