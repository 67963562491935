.loginline {
    display: flex;
    flex-direction: column;  
    justify-content: center;  
    margin: 8px 0;
    text-align: center;
    align-items: center;
  }

  .logintext {
    width: fit-content;
    border-radius: 4px;
    padding: 14px 20px;
    cursor: pointer;
  }

  .button {
    border-radius: 8px;
    border: 1px solid #00000f;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    width: fit-content;
    padding: 8px;
    transition-duration: 0.4s;
    margin-left: 4px;
  }
  
  .button:hover {
    background-color: #afb8af; /* Green */
    color: white;
  }
  