body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.src_container__2i9Fv {
  padding: 0 2rem;
}

.src_main__3KI7- {
  min-height: 85vh;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.src_imageItem__2NFT_ {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.src_description__2B1cY {
  margin: 1rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.src_pdfButton__2UtlJ {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.src_lines__1uUCO {
  display: flex;
  flex-direction: row;  
  justify-content: center;  
  padding: 0 20%;
  margin: 8px 0;
}

h1, h2 {
  text-align: center;
}

.src_urltext__16qRN {
  width: 100%;
  border-radius: 4px;
  padding: 14px 20px;
  cursor: pointer;
}

.src_intervalsarea__pimVq {
  width: 100%;
  border-radius: 4px;
  border: 2px solid #00000f;
  box-sizing: border-box;
  margin: 8px 0;
  padding: 14px 20px;
  cursor: pointer;  
  resize: none;
}

.src_logo__lfch- {
  height: 1em;
  margin-left: 0.5rem;
}

.src_button__2zzEs {
  border-radius: 8px;
  border: 1px solid #00000f;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px;
  transition-duration: 0.4s;
  margin-left: 4px;
}

.src_button__2zzEs:hover {
  background-color: #afb8af; /* Green */
  color: white;
}

.src_tooltip__U3PiA .src_tooltiptext__1VzzA {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  
  bottom: 100%;
  left: 100%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 1s;
}

.src_tooltip__U3PiA {
  position: relative;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}  

.src_tooltip__U3PiA:hover .src_tooltiptext__1VzzA {
  visibility: visible;
  opacity: 1;
}

.src_txtboxlarge__1I5T_ {
  width: 70%;
}

.src_txtboxfull__13dSr {
  width: 100%;
}

@media (max-width: 920px) {
    .src_main__3KI7- {
    padding: 0;
  }

  .src_container__2i9Fv {
    padding: 0;
  }

  .src_lines__1uUCO {
    padding: 0 0.9rem;
  }
}
.header_header__OvuDZ a:hover,
.header_header__OvuDZ a:focus,
.header_header__OvuDZ a:active {
  text-decoration: underline;
}

.header_title__4BYXv {
  margin: 0;
  line-height: 1.15;
  font-size: 1.5rem;
  margin: 2rem 0;

  background-color: #fff;
  color: #000;
  padding: 20px;
  text-align: center;
}

.header_title__4BYXv,
.header_description__VeAoW {
  text-align: center;
}

.header_links__GeLwb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  padding: 10px;
}

.header_links__GeLwb .header_item__2RNtz {
  padding-left: 1rem;
}

.header_header__OvuDZ {
  background: #fff;
  /* position: fixed; */
  top: 0;
  width: 100%;
  margin-top: 10px;
}

.header_header__OvuDZ a {
  color: #000;
  text-decoration: none;
}

.header_sticky__Kv9qC {
  position: fixed;
  top: 0;
  width: 100%;
}

.header_sticky__Kv9qC+.header_content__1FJrF {
  padding-top: 102px;
}
.main {
    min-height: 85vh;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
.footer_footer__b1U_7 {
    display: flex;
    flex: 1 1;
    padding: 2rem 0;
    border-top: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
  }
  
  .footer_footer__b1U_7 a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
.loader_searching__HE2cl{
    display: block;
    text-align: center;
  }
.sheet-item_image__2jHCt {
    width: 60%;
    text-align: center;
}

.sheet-item_divImage__2bPO7 {
    text-align: center;
}

.sheet-item_navigationButton__2OMZD {
    text-align: center;
    font-size: 35px;
}

.sheet-item_pdfLabel__2Ws4I {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.sheet-item_pdf__1blW7 {
    height: 800px;
    width: 100%;
}

.sheet-item_actionMenu__3FtWo {
    flex: 1 1;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

@media (max-width: 920px) {
    .sheet-item_image__2jHCt {
        width: 100%;
    }
}
.login_loginline__19agK {
    display: flex;
    flex-direction: column;  
    justify-content: center;  
    margin: 8px 0;
    text-align: center;
    align-items: center;
  }

  .login_logintext__3RiyM {
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    padding: 14px 20px;
    cursor: pointer;
  }

  .login_button__2TamI {
    border-radius: 8px;
    border: 1px solid #00000f;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px;
    transition-duration: 0.4s;
    margin-left: 4px;
  }
  
  .login_button__2TamI:hover {
    background-color: #afb8af; /* Green */
    color: white;
  }
  
p {
    text-align: center;
}

.member_title__MUJ6z {
    text-align: center;
  }

  .member_links__1gZLu {
    display: flex;
    flex-direction: row;
    justify-content: center;  
  }
p {
  text-align: center;
}

.member-header_title__TZSNd {
  text-align: center;
}

.member-header_links__TrOQG {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.member-header_links__TrOQG .member-header_item__PHcJ9 {
  padding-left: 1rem;
}
p {
    text-align: center;
}

.contribute_title__AauOo {
    text-align: center;
  }

  .contribute_links__1ox3E {
    display: flex;
    flex-direction: row;
    justify-content: center;  
  }
.processing-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #aaabbb;
    opacity: 0.4;
    z-index: 10;
    pointer-events:none;
}

.processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    pointer-events:none;
}
